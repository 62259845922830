@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.mobileWrap {
  overflow: hidden;
  position: relative;
  padding: rpx(15) rpx(12) rpx(22) rpx(12);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box1 {
  width: rpx(171);
  height: rpx(214);
  margin-bottom: rpx(9);
}

.box1:nth-child(1) .box {
  width: rpx(171);
  height: rpx(214);
  background: url("./img/1.png") no-repeat center center;
  background-size: rpx(171) rpx(214);
}

.box1:nth-child(2) .box {
  width: rpx(171);
  height: rpx(214);
  background: url("./img/2.png") no-repeat center center;
  background-size: rpx(171) rpx(214);
}

.box1:nth-child(3) .box {
  width: rpx(171);
  height: rpx(214);
  background: url("./img/3.png") no-repeat center center;
  background-size: rpx(171) rpx(214);
}

.box1:nth-child(4) .box {
  width: rpx(171);
  height: rpx(214);
  background: url("./img/4.png") no-repeat center center;
  background-size: rpx(171) rpx(214);
}

.box {
  width: rpx(171);
  height: rpx(214);
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  perspective: 1000px;
  transition: 0.5s ease-in-out;
  z-index: 1;
}

.mask {
  width: rpx(171);
  height: rpx(214);
  background: url("./img/maskBg.png") no-repeat center center;
  background-size: rpx(171) rpx(214);
  color: #fff;
  font-size: rpx(10);
  position: absolute;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: rotateY(-180deg);
  transition: 0.5s ease-in-out;
  padding: rpx(12) rpx(13);
  z-index: 2;
}

.box1:nth-child(1) .mask {
}

.box1:nth-child(2) .mask {
}

.box1:nth-child(3) .mask {
}

.box1:nth-child(4) .mask {
}

.maskDetail {
  font-size: rpx(10);
  color: #ffffff;
  line-height: rpx(17);
  margin-bottom: rpx(10);
  height: rpx(90);
  overflow: hidden;
  text-align: justify;
}

.boxTitle {
  margin-top: rpx(111);
  margin-bottom: rpx(5);
  color: #000000;
  font-size: rpx(14);
  text-align: center;
  font-weight: 600;
}


.detail {
  padding: 0 rpx(14);
  line-height: rpx(14);
  font-size: rpx(10);
  color: #98A4B0;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.checkButton {
  margin-top: rpx(6);
  text-align: center;
  font-size: rpx(10);
  color: #4D71F9;
}

.arrowIcon {
  width: rpx(14);
  height: rpx(13);
}

.maskTag {
  display: flex;
  flex-wrap: wrap;
}

.maskTags {
  margin: 0 rpx(1) rpx(6) rpx(1);
  padding: rpx(2) rpx(4);
  border: rpx(1) solid #ffffff;
  border-radius: rpx(1);
  color: #ffffff;
  font-size: rpx(10);
}


