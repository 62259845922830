@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.mobileProductGoodness {
  padding: rpx(26) rpx(20) 0 rpx(20);
  width: 100%;
}

.productTitle {
  margin:  0 0 rpx(40) 0;
  text-align: center;
}

.productGoodness {
  width: rpx(225);
  height: rpx(36);
}

.productCard {
}

.cardRow {
  margin-bottom: rpx(30);
}

.cardTitle {
  margin-bottom: rpx(8);
  font-size: rpx(16);
  color: #000000;
  font-weight: 600;
}

.cardIcon {
  width: rpx(26);
  height: rpx(26);
  margin-right: rpx(6);
}

.cardDetail {
  font-size: rpx(12);
  color: #75818E;
  line-height: rpx(18);
}
