@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

@media (min-width: 1440px) {
  .Footer {
    background: #1A1A1A;
    padding: 57px 0 !important;
  }

  .footerContent {
    margin: 0 auto;
    width: 1100px !important;
    display: flex;
    justify-content: space-between;
  }

  .lineDiv {
    width: 210px !important;
    text-align: left;
  }

  .qrLineDiv {
    width: 250px !important;

  }

  .footerLogo {
    width: 207px !important;
    margin-bottom: 50px !important;
  }

  .logoTip {
    font-size: 13px !important;
    color: rgba(255, 255, 255, 0.6);
  }

  .title {
    text-align: left;
    font-size: 14px !important;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 19px !important;
  }

  .phoneTitle {
    text-align: left;
    font-size: 14px !important;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 10px !important;
  }

  .phone {
    font-size: 18px !important;
    color: #ffffff;
    margin-bottom: 15px !important;
    text-align: left;
  }

  .buttonDiv {
    display: flex;
    flex-wrap: wrap;
  }

  .button {
    width: 50%;
    font-size: 13px !important;
    text-align: left;
    margin-bottom: 20px !important;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }
  }

  .subFont {
    text-align: left;
    font-size: 13px !important;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px !important;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .copyRight {
    height: 47px !important;
    line-height: 47px !important;
    text-align: center;
    font-size: 13px !important;
    color: rgba(255, 255, 255, 0.6);
    background: #000000;

    a {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .qrcodeDiv {
    display: flex;
    justify-content: space-between;
  }

  .qrDiv {
    width: 50%;
  }

  .qrcodeImg {
    width: 100px !important;
    height: 100px !important;
    margin-bottom: 6px !important;
  }

  .qrcodeFont {
    text-align: left;
    font-size: 13px !important;
    color: rgba(255, 255, 255, 0.6);
  }
}


.Footer {
  background: #1A1A1A;
  padding: rpx(57) 0 rpx(57) 0;
}

.footerContent {
  margin: 0 auto;
  width: rpx(1100);
  display: flex;
  justify-content: space-between;
}

.lineDiv {
  width: rpx(210);
  text-align: left;
}

.qrLineDiv {
  width: rpx(250);

}

.footerLogo {
  width: rpx(205);
  margin-bottom: rpx(50);
}

.logoTip {
  font-size: rpx(13);
  color: rgba(255, 255, 255, 0.6);
}

.title {
  text-align: left;
  font-size: rpx(14);
  color: #ffffff;
  font-weight: 600;
  margin-bottom: rpx(19);
}

.phoneTitle {
  text-align: left;
  font-size: rpx(14);
  color: #ffffff;
  font-weight: 600;
  margin-bottom: rpx(10);
}

.phone {
  font-size: rpx(18);
  color: #ffffff;
  margin-bottom: rpx(15);
  text-align: left;
}

.buttonDiv {
  display: flex;
  flex-wrap: wrap;
}

.button {
  width: 50%;
  font-size: rpx(13);
  text-align: left;
  margin-bottom: rpx(20);
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
}

.subFont {
  text-align: left;
  font-size: rpx(13);
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: rpx(20);

  &:last-child {
    margin-bottom: 0;
  }
}

.copyRight {
  height: rpx(47);
  line-height: rpx(47);
  text-align: center;
  font-size: rpx(13);
  color: rgba(255, 255, 255, 0.6);
  background: #000000;
}

.qrcodeDiv {
  display: flex;
  justify-content: space-between;
}

.qrDiv {
  width: 50%;
}

.qrcodeImg {
  width: rpx(100);
  height: rpx(100);
  margin-bottom: rpx(6);
}

.qrcodeFont {
  text-align: left;
  font-size: rpx(13);
  color: rgba(255, 255, 255, 0.6);
}
