@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.MobileLine {
  width: 100%;
}

.content {
  padding: 0 rpx(15) rpx(26) rpx(15);
}

.aboutTitle {
  margin: 0 0 rpx(18) 0;
  text-align: center;
}

.lineImg {
  width: rpx(178);
  height: rpx(36);
}

.rowLine {
  font-size: rpx(14);
  color: #525252;
  margin-bottom: rpx(8);
}

.line {
  width: 100%;
  height: rpx(5);
  background: rgba(26, 26, 26, 0.25);
}


.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rpx(114);
  color: #ffffff;
  flex-direction: column;
  font-size: rpx(12);
  text-align: center;
  background: #1A1A1A;
}
