@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

@media (min-width: 1440px) {
  .wrap {
    width: 1224px !important;
    overflow: hidden;
    position: relative;
    margin: 45px auto 53px auto !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .box1 {
    width: 266px !important;
    height: 331px !important;
  }

  .box1:nth-child(1) .box {
    width: 266px !important;
    height: 331px !important;
    background: url("./img/1.png") no-repeat center center;
    background-size: 266px 331px !important;
  }

  .box1:nth-child(2) .box {
    width: 266px !important;
    height: 331px !important;
    background: url("./img/2.png") no-repeat center center;
    background-size: 266px 331px !important;
  }

  .box1:nth-child(3) .box {
    width: 266px !important;
    height: 331px !important;
    background: url("./img/3.png") no-repeat center center;
    background-size: 266px 331px !important;
  }

  .box1:nth-child(4) .box {
    width: 266px !important;
    height: 331px !important;
    background: url("./img/4.png") no-repeat center center;
    background-size: 266px 331px !important;
  }

  .box {
    width: 266px !important;
    height: 331px !important;
    box-sizing: border-box;
    color: #fff;
    position: absolute;
    perspective: 1000px;
    transition: 0.5s ease-in-out;
  }
  .mask {
    width: 266px !important;
    height: 331px !important;
    background: url("./img/maskBg.png") no-repeat center center;
    background-size: 266px 331px !important;
    color: #fff;
    font-size: 14px !important;
    position: absolute;
    backface-visibility: hidden;
    perspective: 1000px;
    transform: rotateY(-180deg);
    transition: 0.5s ease-in-out;
    padding: 30px 15px !important;
  }
  .maskDetail {
    padding: 0 10px;
    font-size: 14px !important;
    color: #ffffff;
    line-height: 20px !important;
    margin-bottom: 29px !important;
    text-align: justify;
  }

  .boxTitle {
    margin-top: 177px !important;
    margin-bottom: 7px !important;
    color: #000000;
    font-size: 18px !important;
    text-align: center;
    font-weight: 600;
  }

  .tagName {
    margin: 0 auto 7px auto !important;
    width: 65px !important;
    height: 24px !important;
    line-height: 24px !important;
    text-align: center;
    font-size: 12px !important;
    color: #E99D46;
    background: linear-gradient(to right, #FFFCF6, #FFF7E9);
  }
  .detail {
    padding: 0 30px !important;
    line-height: 17px !important;
    font-size: 12px !important;
    color: #98A4B0;
    text-align: center;
  }

  .maskTags {
    margin: 0 3px !important;
    padding: 4px 14px !important;
    border: 1px solid #ffffff !important;
    border-radius: 3px !important;
    color: #ffffff;
    font-size: 12px !important;
    margin-bottom: 12px !important;
  }

}


.wrap {
  width: rpx(1224);
  overflow: hidden;
  position: relative;
  margin: rpx(45) auto rpx(53) auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box1 {
  width: rpx(266);
  height: rpx(331);
}

.box1:nth-child(1) .box {
  width: rpx(266);
  height: rpx(331);
  background: url("./img/1.png") no-repeat center center;
  background-size: rpx(266) rpx(331);
}

.box1:nth-child(2) .box {
  width: rpx(266);
  height: rpx(331);
  background: url("./img/2.png") no-repeat center center;
  background-size: rpx(266) rpx(331);
}

.box1:nth-child(3) .box {
  width: rpx(266);
  height: rpx(331);
  background: url("./img/3.png") no-repeat center center;
  background-size: rpx(266) rpx(331);
}

.box1:nth-child(4) .box {
  width: rpx(266);
  height: rpx(331);
  background: url("./img/4.png") no-repeat center center;
  background-size: rpx(266) rpx(331);
}

.box {
  width: rpx(266);
  height: rpx(331);
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  perspective: 1000px;
  transition: 0.5s ease-in-out;
}

.mask {
  width: rpx(266);
  height: rpx(331);
  background: url("./img/maskBg.png") no-repeat center center;
  background-size: rpx(266) rpx(331);
  color: #fff;
  font-size: rpx(14);
  position: absolute;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: rotateY(-180deg);
  transition: 0.5s ease-in-out;
  padding: rpx(30) rpx(15);
}

.box1:nth-child(1) .mask {
}

.box1:nth-child(2) .mask {
}

.box1:nth-child(3) .mask {
}

.box1:nth-child(4) .mask {
}

.maskDetail {
  padding: 0 rpx(10);
  font-size: rpx(14);
  color: #ffffff;
  line-height: rpx(20);
  margin-bottom: rpx(29);
  text-align: justify;
}

.boxTitle {
  margin-top: rpx(177);
  margin-bottom: rpx(7);
  color: #000000;
  font-size: rpx(18);
  text-align: center;
  font-weight: 600;
}

.tagName {
  margin: 0 auto rpx(7) auto;
  width: rpx(65);
  height: rpx(24);
  line-height: rpx(24);
  text-align: center;
  font-size: rpx(12);
  color: #E99D46;
  background: linear-gradient(to right, #FFFCF6, #FFF7E9);
}

.tagName1 {
  @extend .tagName;
}

.tagName2 {
  @extend .tagName;
  background: #FFF0F0;
  color: #EC8181;
}

.tagName3 {
  @extend .tagName;
  background: #F1F1FF;
  color: #918CF3;
  min-width: 70px;
}

.detail {
  padding: 0 rpx(30);
  line-height: rpx(17);
  font-size: rpx(12);
  color: #98A4B0;
  text-align: center;
}

.maskTag {
  display: flex;
  flex-wrap: wrap;
}

.maskTags {
  margin: 0 rpx(3);
  padding: rpx(4) rpx(7);
  border: rpx(1) solid #ffffff;
  border-radius: rpx(3);
  color: #ffffff;
  font-size: rpx(12);
  margin-bottom: rpx(12);
}


