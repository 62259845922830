@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.root{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
  overflow: hidden;
}

.wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialogForm {
  position: relative;
  width: rpx(750);
  max-width: 750px;
  min-height: 550px;
  max-height: 668px;
  height: rpx(668);
  background: #ffffff;
  border-radius: rpx(10);
  overflow: hidden;
}

.close {
  position: absolute;
  top: rpx(17);
  right: rpx(17);
  width: rpx(36);
  height: rpx(36);
  max-width: 36px;
  max-height: 36px;
  cursor: pointer;
}

.dialogHeader {
  width: 100%;
  height: rpx(225);
  max-height: 225px;
  margin-bottom: rpx(30);
}
