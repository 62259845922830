@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.solution {
  margin: 0 auto;
  width: 100%;
}

.solTitle {
  text-align: center;
  margin-bottom: rpx(22);
}

.solutionTitle {
  margin: 0 auto;
  width: rpx(185);
  height: rpx(36);
}

.tabDiv {
}

.tabLeft {
  width: 100%;
  margin-bottom: rpx(6);
}

.leftTabTitle {
  font-size: rpx(28);
  color: #000000;
  font-weight: 600;
  margin: rpx(9) 0;
}

.leftTabDetail {
  font-size: rpx(14);
  line-height: rpx(25);
  text-align: justify;
}

.tabRight {
  position: relative;
  width: 100%;
  height: rpx(260);
  line-height: rpx(260);
  text-align: center;
  background: url("./img/bluePointBg.png") no-repeat center center;
  background-size: cover;
}

.yunyingDiv {
  position: relative;
  width: rpx(473);
  height: rpx(354);
  margin: 0 auto;
}


.showImg {
  width: 100%;
}

.showImg1 {
  width: rpx(345);
}

.showImg2 {
  width: rpx(343);
  height: rpx(224);
}

.showImg3 {
  width: rpx(343);
  height: rpx(260);
}

.showImg4 {
  width: rpx(344);
  height: rpx(224);
}

.showImg5 {
  width: rpx(344);
  height: rpx(221);
}

.showImg6 {
  width: rpx(343);
  height: rpx(232);
}

.showImg7 {
  width: rpx(344);
  height: rpx(258);
}

.showImg8 {
  width: rpx(343);
  height: rpx(180);
}
