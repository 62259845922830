@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.TopMenu {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  width: rpx(337);
  height: 100%;
  background: rgba(255,255,255,0.95);
}

.active {
  transform: translate3d(rpx(337),0,0);
  transition: all 0.2s ease-in-out;
}

.hidden {
  transform: translate3d(0px,0,0);
  transition: all 0.2s ease-in-out;
}

.content{
  padding: rpx(44) rpx(27);
  width: 100%;
  height: 100%;
  position: relative;
}

.close {
  position: absolute;
  top: rpx(19);
  right: rpx(19);
  width: rpx(13);
  height: rpx(13);
}

.buttonRow {
  padding: rpx(13) 0 ;
  font-size: rpx(16);
  color: #111111;
  border-bottom: rpx(1) solid #dfdfdf;
}
