@import "src/Components/SCSS/mixins";

.root {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  padding: rpx(15);

  :global {
    .adm-form-item-label {
      font-size: rpx(14)!important;
      color: #111111;
    }

    .adm-input-element {
      font-size: rpx(14)!important;
    }

    .adm-text-area-element {
      font-size: rpx(14)!important;
    }

    .adm-form-item-feedback-error {
      text-align: right;
    }

    .adm-button:not(.adm-button-default){
      background: #4D71F9;
      font-size: rpx(14);
    }
  }
}

.headerImg {
  width: 100%;
  height: rpx(130);
}

.button {
  padding: rpx(20);
}
