@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.MobileAbout {
  padding: 0 rpx(15) 0 rpx(15);
  width: 100%;
}

.aboutTitle {
  margin:  0 0 rpx(18) 0;
  text-align: center;
}

.aboutImg {
  width: rpx(128);
  height: rpx(36);
}

.flexDiv {
}

.leftDiv {
}

.rowFont {
  text-indent: 2em;
  font-size: rpx(14);
  color: #000000;
  text-align: justify;
  margin-bottom: rpx(30);
}

.rightDiv {
  width: 100%;
  height: rpx(187);
  margin-bottom: rpx(15);
}

.rightImg {
  width: 100%;
  height: rpx(187);
}
