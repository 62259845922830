@import "src/Components/SCSS/mixins";

.root{
  background: #fff;
  border: 1px solid #51a2de;
  height: 50px;
  color: #51a2de;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
}
