// USER VARIABLES SECTION
// =======================

$body-bg: #f5f5f5;
$primary-color: #922000;

$mobile-breakpoint: 750px;
$content-width: 1200px;

$header-height-mobile: 100mrpx;
$footer-height-mobile: 230mrpx;
// $accent: orange // Accent Color
// $fontsize: 16px // Body Font Size
// $textcolor: #333 // Text Color
// $lineheight: 1.65 // Document Line Height
// $userfont: roboto-oh5, sans-serif // User Font Family
// $systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif // System Font Family

// // Bootstrap compatible gutter variable => $gutter
// $grid-gutter-width: 30px
// $gutter: $grid-gutter-width

// // IMPORT SECTION

// @import 'node_modules/bootstrap/scss/bootstrap-reboot.scss' // Bootstrap Reboot collection
// @import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss' // Bootstrap Breakpoints mixin
// // @import 'node_modules/bootstrap/scss/bootstrap-grid.scss' // Optional Bootstrap Grid
// // @import 'node_modules/bootstrap/scss/_utilities.scss' // Optional Bootstrap Utilites

// // FONTS LOAD SECTION

// @mixin font($fname, $fstyle, $fweight, $furl)
// 	@font-face
// 		font-family: $fname
// 		font-style: $fstyle
// 		font-weight: $fweight
// 		src: url($furl) format('woff2')

// @include font('roboto-oh5', normal, 400, '../fonts/roboto-regular-webfont.woff2')
// @include font('roboto-oh5', italic, 400, '../fonts/roboto-italic-webfont.woff2')
// @include font('roboto-oh5', normal, 700, '../fonts/roboto-bold-webfont.woff2')
// @include font('roboto-oh5', italic, 700, '../fonts/roboto-bolditalic-webfont.woff2')

// // GENERAL CSS SETTINGS

// ::placeholder
// 	color: #666

// ::selection
// 	background-color: $accent
// 	color: #fff

// input, textarea
// 	outline: none
// 	&:focus:required:invalid
// 		border-color: red
// 	&:required:valid
// 		border-color: green

// body
// 	font-family: $userfont
// 	font-size: $fontsize
// 	line-height: $lineheight
// 	color: $textcolor
// 	min-width: 320px
// 	position: relative
// 	overflow-x: hidden