@import "src/Components/SCSS/mixins";
@import "src/Components/SCSS/config";

.MobileTop {
  position: relative;
}

.topBg {
  width: 100%;
  height: rpx(602);
}

.menuIcon {
  width: rpx(19);
  height: rpx(15);
  position: absolute;
  top: rpx(25);
  right: rpx(19);
}

.freeButton {
  position: absolute;
  top: rpx(290);
  left: 50%;
  transform: translateX(-50%);
  width: rpx(189);
  height: rpx(44);
}
